import * as React from "react";
import Link from "next/link";
import axios from "axios";
import Image from "next/image";
import Marquee from "react-fast-marquee";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import { ChevronLeft, ChevronRight } from "lucide-react";

import FAQ from "@/components/FAQ";
import Layout from "@/components/layout";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import MasterclassCard from "@/components/Masterclass/MasterclassCard";
import { cn } from "@/utils/cn";
import { Button } from "@/components/ui/button";
import { financeId } from "./masterclasses";
import webengage from "@/webengage";
import mixpanel from "@/utils/mixpanel";
import useKey from "@/hooks/use-key";
import Head from "next/head";

const categories = [
  {
    label: "All",
    slug: "all",
  },
  {
    label: "Intraday Trading",
    slug: "intraday",
    id: "",
    image: "/categories/intraday.png",
    experts: 7,
    hex: "#E8FBF5",
    borderColor: "#00755033",
    hoverColor: "#daefe9",
  },
  {
    label: "Swing Trading",
    slug: "swing",
    id: "",
    image: "/categories/swing.png",
    experts: 8,
    hex: "#FFF6D7",
    borderColor: "#7B5F0033",
    hoverColor: "#f9edc4",
  },
  {
    label: "Algo Trading",
    slug: "algo",
    id: "",
    image: "/categories/algo.png",
    experts: 6,
    hex: "#F3F6FF",
    borderColor: "#00208233",
    hoverColor: "#e5ebf9",
  },
  {
    label: "Forex Trading",
    slug: "forex",
    id: "clxj85zlo001ok71iplfu7i53",
    image: "/categories/forex.png",
    experts: 9,
    hex: "#D7EFFF",
    borderColor: "#004C7F33",
    hoverColor: "#c7e5f9",
  },
];

const socialProofs = [
  {
    metricNumber: "40K+ ",
    metricTitle: " Hours",
    description: "Of LIVE learning",
  },
  {
    metricNumber: "3 ",
    metricTitle: " Years",
    description: "Of building community",
  },
  {
    metricNumber: "7 ",
    metricTitle: " Lakh+",
    description: "Users attended free Masterclasses",
  },
  {
    metricNumber: "100",
    metricTitle: "+",
    description: "Experts onboarded to deliver best content",
  },
  {
    metricNumber: "50",
    metricTitle: "K+",
    description: "Customers learnt and became successful traders",
  },
];

const corePillars = [
  {
    title: "Expert with Verified PnL",
    description: "Learn from pros with proven profits.",
    image: "/core-pillars/expert.gif",
    hex: "#E8FBF5",
  },
  {
    title: "Live Market Support",
    description: "Get real-time expert trading help.",
    image: "/core-pillars/market-support.gif",
    hex: "#FFF6D7",
  },
  {
    title: "Gamified Learning",
    description: "Master trading through fun, interactive games.",
    image: "/core-pillars/gamified-learning.gif",
    hex: "#F3F6FF",
  },
];

export async function getServerSideProps() {
  try {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_BASE_API}masterclasses?select=slots&getFutureSlots=true&category=Finance`,
      {
        headers: { "Accept-Encoding": "gzip,deflate,compress" },
      }
    );

    const res2 = await axios.get(
      `${process.env.NEXT_PUBLIC_BASE_API}categories/sub-categories/${financeId}`,
      {
        headers: { "Accept-Encoding": "gzip,deflate,compress" },
      }
    );

    return {
      props: { masterclasses: res.data, tags: res2.data },
    };
  } catch (err) {
    return {
      props: {
        masterclasses: [],
        tags: [],
      },
    };
  }
}

export default function Home({
  masterclasses,
  tags,
}: {
  masterclasses: any;
  tags: any;
}) {
  const router = useRouter();
  const [api, setApi] = React.useState<CarouselApi>();
  const [isMobile, setIsMobile] = React.useState<boolean>(() => {
    if (typeof window === "undefined") return false;
    return window.innerWidth <= 768;
  });
  const [currentSlide, setCurrentSlide] = React.useState<number>(0);
  const [fetchedMasterclasses, setFetchedMasterclasses] =
    React.useState(masterclasses);
  const tagsArray = tags?.data || [];
  const TAGS: { [key: string]: { name: string; id: string } } = {};

  tagsArray.forEach((tag: any) => {
    TAGS[tag.name.toUpperCase()] = { name: tag?.name, id: tag?.id };
  });
  const [activeCategory, setActiveCategory] = React.useState<{
    name: string;
    id: string;
  } | null>(null);

  const filteredMasterclasses = async () => {
    if (!activeCategory) return;

    try {
      const { data: response } = await axios.get(
        `${process.env.NEXT_PUBLIC_BASE_API}masterclasses/masterclass-by-subcategory/${activeCategory?.id}?getFutureSlots=true`,
        {
          headers: { "Accept-Encoding": "gzip,deflate,compress" },
        }
      );
      setFetchedMasterclasses(response);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    if (activeCategory) {
      filteredMasterclasses();
    } else {
      setFetchedMasterclasses(masterclasses);
    }
  }, [activeCategory, masterclasses]);

  React.useEffect(() => {
    if (typeof window === "undefined") return;
    setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth <= 768);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setIsMobile(window.innerWidth <= 768);
      });
    };
  }, []);

  // ----- carousel -----
  const slides: any[] | null = useKey(
    "tradewise_main_website_home_page_carousel",
    {
      json: true,
    }
  );

  React.useEffect(() => {
    if (!api) {
      return;
    }

    setCurrentSlide(api.selectedScrollSnap());

    api.on("select", () => {
      setCurrentSlide(api.selectedScrollSnap());
    });
  }, [api]);

  const changeCurrentSlide = (index: number) => {
    if (api) {
      api.scrollTo(index);
    }
  };

  React.useEffect(() => {
    const _webengage = webengage();
    if (_webengage)
      _webengage.track("Homepage Viewed", {
        source: "Tradewise",
      });
  }, []);

  const handleNext = () => {
    if (api) {
      api.scrollNext();
    }
  };

  const handlePrevious = () => {
    if (api) {
      api.scrollPrev();
    }
  };

  return (
    <>
      <Head>
        <title>
          Trading Courses Online | Master Trading Skills with Tradewise
        </title>
        <meta
          name="description"
          content="Learn to trade with expert-designed courses at Tradewise. Master strategies for stocks, forex, and crypto trading to enhance your financial journey today."
        />
        <link
          rel="canonical"
          href="https://www.tradewise.com"
          key="canonical"
        />
      </Head>
      <Layout isContainerHidden>
        <section className="mt-4 relative py-2">
          <button
            onClick={handleNext}
            className="p-0 h-9 w-9 absolute top-1/2 bottom-1/2 -translate-y-1/2 right-12 z-20 max-md:hidden"
          >
            <ChevronRight size={16} />
          </button>
          <button
            onClick={handlePrevious}
            className="p-0 h-9 w-9 absolute top-1/2 bottom-1/2 -translate-y-1/2 left-12 z-20 max-md:hidden"
          >
            <ChevronLeft size={16} />
          </button>
          <Carousel
            opts={{ loop: true, align: "center" }}
            setApi={setApi}
            plugins={[
              Autoplay({
                delay: 3500,
              }),
            ]}
          >
            <CarouselContent className="gap-0">
              {slides?.map((slide, index) => (
                <CarouselItem
                  key={index}
                  className="relative pl-1.5 xl:pl-3.5 basis-[83%] xl:basis-[80%] w-full mx-auto"
                >
                  <Link
                    href={String(slide.slug)}
                    onClick={() => {
                      mixpanel.track(`${slide.slug} clicked`, {
                        page_location: "Homepage",
                        location: "banner",
                      });
                    }}
                  >
                    <Image
                      src={
                        isMobile
                          ? slide["mobile_image_(540x218)"]
                          : slide["desktop_image_(1200x360)"]
                      }
                      alt="carousel image"
                      width={1200}
                      height={600}
                      quality={100}
                      priority
                      loading="eager"
                      className="rounded-lg"
                    />
                  </Link>
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
          <div className="flex items-center justify-center mt-2.5 gap-1">
            {slides?.map((_, index) => (
              <span
                key={index}
                onClick={() => changeCurrentSlide(index)}
                className={cn(
                  "inline-block rounded-full h-[7px] md:h-2 transition-all duration-300 ease-linear",
                  currentSlide === index
                    ? "w-5 lg:w-[23px] bg-gray-500"
                    : "w-[7px] lg:w-2 cursor-pointer bg-gray-300"
                )}
              />
            ))}
          </div>
        </section>

        <section className="container w-full relative mt-2.5 sm:mt-4 md:mt-7 py-2">
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-center">
            So many reasons to start
          </h2>
          <div
            className="mt-3.5 md:mt-5 grid grid-cols-2 max-md:grid-rows-2 md:grid-cols-4 gap-2 md:gap-4 2xl:gap-8 place-items-center cursor-pointer"
            onClick={() => {}}
          >
            {categories.map((category) => {
              if (category.label === "All") return null;
              return (
                <div
                  key={category.slug}
                  className="p-4 pt-5 w-full h-full rounded-lg mx-auto border-2 border-solid transition-all ease-linear"
                  style={{
                    backgroundColor: category.hex,
                  }}
                  onClick={() => {
                    router.push(`/masterclasses?category=${category.slug}`);
                    const _webengage = webengage();
                    if (_webengage)
                      _webengage.track("Category Clicked", {
                        category: category.label,
                        source: "Tradewise",
                      });
                    mixpanel.track("explore_category", {
                      page_location: "Homepage",
                      page_referrer: document.referrer,
                      location: "tabs",
                      subcategory: activeCategory?.name,
                    });
                  }}
                  onMouseEnter={(e: any) => {
                    e.currentTarget.style.backgroundColor = category.hoverColor;
                    e.currentTarget.style.borderColor = category.borderColor;
                  }}
                  onMouseLeave={(e: any) => {
                    e.currentTarget.style.backgroundColor = category.hex;
                    e.currentTarget.style.borderColor = "#e5e7eb";
                  }}
                >
                  <div className="h-10 md:h-14 w-10 md:w-14 relative mx-auto">
                    <Image
                      src={category.image as string}
                      alt={category.label}
                      fill
                    />
                  </div>
                  <h3 className="text-center text-base md:text-lg font-semibold text-foreground mt-2">
                    {category.label}
                  </h3>
                  <p className="text-center text-sm md:text-base text-zinc-400">
                    {category.experts} Experts
                  </p>
                </div>
              );
            })}
          </div>
        </section>
        <section className="container w-full relative py-2.5 mt-4 md:mt-7 lg:mt-8">
          <div className="flex items-center justify-between gap-4 md:gap-6">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold max-md:hidden">
              Explore our masterclasses
            </h2>
            <div className="overflow-x-auto hide_scrollbar max-md:flex max-md:justify-evenly max-md:items-center max-md:w-full md:space-x-4">
              <button
                onClick={() => setActiveCategory(null)}
                className={cn(
                  "rounded-full whitespace-nowrap text-sm md:text-[15px] px-3 md:px-3.5 py-1 md:py-1.5 font-medium capitalize border-none",

                  !activeCategory
                    ? "bg-foreground !text-white hover:text-white"
                    : "bg-primary/10 text-foreground"
                )}
              >
                All
              </button>
              {tagsArray.map((tag: any) => (
                <button
                  onClick={() => {
                    setActiveCategory(
                      TAGS[tag?.name?.toUpperCase() as keyof typeof TAGS]
                    );
                    mixpanel.track("explore_category", {
                      page_location: "Homepage",
                      page_referrer: document.referrer,
                      location: "tabs",
                      subcategory: activeCategory?.name,
                    });
                  }}
                  className={`rounded-full text-sm md:text-[15px] px-3 md:px-3.5 py-1 md:py-1.5 font-medium capitalize border-none
                  ${
                    tag?.name === activeCategory?.name
                      ? "bg-foreground !text-white hover:text-white"
                      : "bg-primary/10 text-foreground/80 hover:text-black"
                  }`}
                  key={tag?.id}
                >
                  {tag?.name}
                </button>
              ))}
            </div>
          </div>
          <div className="mt-4 md:mt-5 p-2 w-full overflow-x-scroll flex items-stretch gap-5 hide_scrollbar">
            {fetchedMasterclasses?.data
              ?.slice(0, 10)
              .map((masterclass: any, index: number) => (
                <MasterclassCard
                  key={masterclass?.id}
                  initial={{ opacity: 0, translateY: 40 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  transition={{
                    duration: 0.4,
                    delay: 0.2 * index,
                    type: "tween",
                  }}
                  masterclass={masterclass}
                />
              ))}
          </div>
          <div className="w-full grid place-items-center mt-6">
            <Button
              onClick={() => router.push("/masterclasses")}
              className="px-5 text-white"
            >
              View All
            </Button>
          </div>
        </section>

        <section className="container w-full relative py-2 md:py-4 mt-3 md:mt-5">
          <motion.h3
            initial={{ opacity: 0, translateY: 40 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{
              duration: 0.4,
              type: "tween",
            }}
            viewport={{ once: true }}
            className="text-center text-xl sm:text-2xl md:text-3xl font-bold"
          >
            Creating impact around the world with
          </motion.h3>
          <div className="grid grid-cols-2 gap-2.5 place-items-stretch mx-auto mt-6 md:mt-8">
            {socialProofs.map((proof, index) => {
              const isLast = index === socialProofs.length - 1;

              return (
                <div
                  key={index}
                  className={cn(
                    "bg-[#EFF2F666] hover:bg-[#eeeeee66] p-4 sm:p-5 md:p-6 rounded-lg flex flex-col gap-y-1.5 justify-center items-center w-full transition-all",
                    isLast && "col-span-2"
                  )}
                >
                  <div className="flex gap-1 text-center text-balance text-xl sm:text-2xl md:text-3xl font-bold text-foreground/85 tracking-wide">
                    <motion.div
                      initial={{ opacity: 0, translateY: 40 }}
                      whileInView={{ opacity: 1, translateY: 0 }}
                      transition={{
                        duration: 0.3,
                      }}
                      viewport={{ once: true }}
                    >
                      {proof.metricNumber}
                    </motion.div>
                    <motion.div
                      initial={{ translateY: 30 }}
                      whileInView={{ translateY: 0 }}
                      transition={{
                        duration: 0.3,
                        delay: 0.3,
                      }}
                      viewport={{ once: true }}
                    >
                      {proof.metricTitle}
                    </motion.div>
                  </div>
                  <p className="max-sm:text-sm text-balance text-center text-foreground/40 tracking-wide">
                    {proof.description}
                  </p>
                </div>
              );
            })}
          </div>
        </section>

        <section className="max-md:container w-full relative mt-4 md:mt-7 lg:mt-9 py-2">
          <div className="border-2 border-[#FFD691] bg-gradient-to-b from-white to-[#FFF7EA] rounded-2xl py-5 px-8 md:hidden">
            <div>
              <div className="mx-auto relative h-[70px] w-[140px] sm:h-[75px] sm:w-[160px]">
                <Image src="/gold/landing.svg" alt="Tradewise Gold" fill />
              </div>
              <p className="font-semibold text-xl text-foreground text-center leading-loose">
                Elevate your trading journey
              </p>
            </div>

            <div>
              <ul className="w-fit mx-auto flex flex-col items-start justify-center gap-1 mt-2">
                <li className="flex items-center gap-1">
                  <Image
                    src="/checkbox.svg"
                    width={20}
                    height={20}
                    alt="Checkbox"
                  />
                  <span className="text-[#161C2B]">
                    Get unlimited recording access
                  </span>
                </li>
                <li className="flex items-center gap-1">
                  <Image
                    src="/checkbox.svg"
                    width={20}
                    height={20}
                    alt="Checkbox"
                  />
                  <span className="text-[#161C2B]">
                    Get 10% discount on all courses
                  </span>
                </li>
              </ul>
              <Link
                href="/gold"
                onClick={() => {
                  mixpanel.track("gold_clicked", {
                    page_location: "Homepage",
                    location: "middle_cta",
                  });
                }}
                className="w-full inline-flex gap-2 items-center justify-center bg-[#AF843B] py-2 px-5 rounded-lg text-white mt-5 font-semibold hover:![text-decoration:none] leading-loose h-12 transition-opacity"
              >
                View all benefits <ChevronRight className="h-4 w-4" />
              </Link>
            </div>
          </div>
          <div className="relative overflow-hidden max-md:hidden w-full bg-[#FDF2CD66] px-24 lg:px-40">
            <Image
              src="/gold/waves.svg"
              width={428}
              height={2056}
              alt="wavy background"
              className="absolute -z-10 -top-3/4 md:left-0 -translate-y-16 opacity-30"
            />
            <Image
              src="/gold/waves2.svg"
              width={662}
              height={1870}
              alt="wavy background"
              className="max-md:hidden -z-10 absolute top-0 right-0 opacity-30"
            />
            <div className="py-6 flex items-center justify-between">
              <div className="flex gap-5 py-10 flex-1 flex-col">
                <h3 className="text-3xl font-bold leading-normal tracking-[-0.125px] text-[#3C4852]">
                  Elevate your trading journey with{" "}
                  <br className="max-lg:hidden" /> Tradewise Gold
                </h3>
                <ul className="flex items-center justify-start gap-6">
                  <li className="flex items-center gap-1">
                    <Image
                      src="/checkbox.svg"
                      width={20}
                      height={20}
                      alt="Checkbox"
                    />
                    <span className="text-[#161C2B]">
                      Get unlimited recording access
                    </span>
                  </li>
                  <li className="flex items-center gap-1">
                    <Image
                      src="/checkbox.svg"
                      width={20}
                      height={20}
                      alt="Checkbox"
                    />
                    <span className="text-[#161C2B]">
                      Get 10% discount on all courses
                    </span>
                  </li>
                </ul>
                <Link
                  href="/gold"
                  className="w-fit inline-flex gap-2 items-center justify-center bg-[#161C2B] py-2 px-5 rounded-lg text-white font-semibold hover:![text-decoration:none] leading-loose h-12 transition-opacity"
                >
                  View all benefits <ChevronRight className="h-4 w-4" />
                </Link>
              </div>
              <div className="relative h-[90px] w-[230px] lg:h-[105px] lg:w-[260px]">
                <Image
                  src="/gold/landing2.webp"
                  alt="Tradewise Gold"
                  fill
                  className="object-contain"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="w-full container relative mt-5 md:mt-7 rounded-xl max-md:[box-shadow:0px_0px_5px_0px_#EFF2F6] p-5 md:px-0">
          <motion.h3
            initial={{ opacity: 0, translateY: 40 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{
              duration: 0.4,
              type: "tween",
            }}
            viewport={{ once: true }}
            className="text-center text-xl sm:text-2xl md:text-3xl font-bold"
          >
            Our Core Pillars
          </motion.h3>
          <div className="flex flex-col md:flex-row items-center justify-center gap-3 md:justify-between md:items-stretch mt-6 md:mt-8">
            {corePillars.map((pillar) => (
              <motion.div
                initial={{ opacity: 0, translateY: 40 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.4, type: "tween" }}
                viewport={{ once: true }}
                key={pillar.title}
                className="p-4 md:p-6 w-full h-full rounded-lg flex flex-col gap-2 md:gap-3 items-center justify-center"
                style={{ backgroundColor: pillar.hex }}
              >
                <Image
                  src={pillar.image}
                  width={85}
                  height={85}
                  alt="decorative gif"
                  className="mix-blend-multiply mt-1"
                />
                <h3 className="text-center text-balance text-[1.25rem] md:text-2xl font-semibold text-foreground/85 leading-relaxed">
                  {pillar.title}
                </h3>
                <p className="text-center text-foreground/40 leading-tight">
                  {pillar.description}
                </p>
              </motion.div>
            ))}
          </div>
        </section>

        <section className="w-full container relative mt-5 rounded-xl py-3">
          <h3 className="mt-3 text-center text-xl sm:text-2xl md:text-3xl font-bold">
            Some Students Feedback
          </h3>
          <div className="relative max-md:px-4 mt-6 md:mt-8">
            <Marquee pauseOnClick pauseOnHover>
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className="relative overflow-hidden mx-2 md:mx-4 rounded-lg h-72 w-full"
                >
                  <Image
                    src={`/feedbacks/${index + 1}.webp`}
                    alt={`Feedback ${index + 1}`}
                    width={280}
                    height={550}
                    quality={85}
                  />
                </div>
              ))}
            </Marquee>
          </div>
        </section>
        <section>
          <FAQ accordianClasses="container" />
        </section>
      </Layout>
    </>
  );
}
